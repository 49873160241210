import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import BeatLoader from 'react-spinners/BeatLoader'
import { Controller, useForm } from 'react-hook-form'
import { graphql, Link } from 'gatsby'

import BDayMobileImage from '../images/event-francis-mercier.jpeg'
// import BDayYears from '../images/bday-years.inline.svg'
import LogoTuya from '../images/logo-white.inline.svg'

import encode from '../helpers/encode'
import '../styles/pages/event-subscription.scss'
import SEO from '../components/seo'

const EventSubscriptionPage = ({ location }) => {
  const [state, setState] = React.useState({})
  const [isSuccess, setIsSuccess] = React.useState(null)
  const { register, handleSubmit, control, errors } = useForm()

  useEffect(() => {
    return () => {
      setIsSuccess(null)
    }
  }, [])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    setIsLoading(true)
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setIsSuccess(true)
        // navigate(form.getAttribute('action'), {
        //   state: { isSuccess: true },
        // })
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <SEO title="Francis Mercier" />
      <div className="md:h-screen md:overflow-hidden bday bg-black flex justify-center">
        <div className="flex flex-col content-center pb-8 md:pb-0 md:grid md:grid-cols-2 md:w-[800px]">
          <div className="w-full self-center justify-center">
            <img
              className="object-contain object-center w-full h-full -mt-8 "
              alt="Confirmation"
              src={BDayMobileImage}
            />
          </div>
          
        </div>
      </div>
    </>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default EventSubscriptionPage
